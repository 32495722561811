import React from 'react';
import {
  Block,
  Column,
  Container,
  Half,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  Actions,
} from '@dragonchain-dev/dragon-kit';
import { FeatureRow, Layout as PageLayout, SEO } from '../components/_index';

import BpsaaSVG from '../assets/images/icons/bpsaa.svg';
import InnovationCircleSVG from '../assets/images/icons/innovation-circle.svg'

const { IMAGES_URI } = process.env;

const About = () => {
  return (
    <PageLayout>
      <SEO
        title="About | Dragonchain - Blockchain as a Service"
        description="Dragonchain is a technology leader in the blockchain space. With security and scalability at the forefront of its operations. We are proudly U.S.-based and part of one of the most technologically rich communities in the country."
      />
      <Hero
        type="code"
        backgroundImage="https://images.dragonchain.com/texture/clouds-blue@2x.jpg"
        video={{
          url: 'https://www.youtube.com/watch?v=sn_nKyE2Suw',
          autoPlay: true,
        }}
      >
        <Title color="white">America's Blockchain</Title>
        <Description color="blue-light">
          <p>
            With our accessible, flexible, and secure platform, we will shape the future of technology to
            protect the world’s data with measurable proof.
          </p>
          <br />
          <p>“What we do now echoes in eternity.” ‒ Marcus Aurelius</p>
          <Actions
            actions={[
              {
                type: 'link',
                name: 'Latest Updates',
                color: 'blue',
                hover: 'blue-dark',
                to: '/blog',
              },
            ]}
          />
        </Description>
      </Hero>
      <Layout>
        <Block center>
          <Title>About Dragonchain</Title>
          <p>
            Originally created at the Walt Disney Company in 2014, Dragonchain is a hybrid blockchain platform
            focused on solving business problems at an enterprise scale. The blockchain industry is projected
            for massive growth in the near future. Gartner, a leading research and advisory company, projects
            that the blockchain industry will grow to $3.1 trillion by 2030.
          </p>
          <br />
          <p>
            Dragonchain holds multiple “holy grail” patents on blockchain technology, including patents on
            blockchain scalability, interoperability, and enterprise process orchestration. With the
            integration of the PRNG at the core of our architecture, we now offer every customer Quantum Safe
            data protection. Dragonchain has proven enterprise scalability, executing over 260MM transactions
            on a business system in just 24 hours.
          </p>
        </Block>
        <Block type="halves">
          <Half
            illustration={{
              src: `${IMAGES_URI}/content/bellevue-chamber-commerce.jpg`,
            }}
          />
          <Half>
            <Title color="black">Community Recognition</Title>
            <ul>
              <li>
                Recognized as Eastside Small Business of the Year 2019 by the Bellevue Chamber of Commerce
              </li>
              <li>
                Received Tech Impact Awards, Gold Winner of Blockchain Category by the Seattle Business
                Magazine
              </li>
            </ul>
          </Half>
        </Block>
        <Block type="halves">
          <Half>
            <Title>Meet Our Founder</Title>
            <p>
              Joe Roets is the Architect, Founder, and Chief Executive Officer of Dragonchain. He has over 20
              years of experience in software architecture, focused primarily on security and scalability. Joe
              has created and led multiple technology startups. He is a visionary and thought leader in
              blockchain technology. He has led or contributed to projects at several companies such as The
              Walt Disney Company, Lockheed Martin, Overstock, Coinbase, and Compuware.
            </p>
          </Half>
          <Half
            illustration={{
              src: `${IMAGES_URI}/content/joe.jpg`,
            }}
          />
        </Block>

        <Block type="halves">
          <Half>
            <div className="logo-farm">
              <div className="inner">
                <div className="logo-farm-logo">
                  <a
                    href="https://circle.cointelegraph.com/profile/Joe-Roets-Architect-Founder-CEO-Dragonchain/eb3bbf5f-2b8c-4f2d-a171-4d45e6127d3e"
                    target="__blank"
                  >
                    <InnovationCircleSVG />
                  </a>
                </div>
              </div>
            </div>
          </Half>

          <Half>
            <div className="logo-farm">
              <div className="inner">
                <div className="logo-farm-logo">
                  <a href="https://bpsaa.vision/" target="__blank">
                    <BpsaaSVG />
                  </a>
                </div>
              </div>
            </div>
          </Half>
        </Block>
      </Layout>
      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Holy Grail Patents" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                title: 'Interchain & Interoperability',
                link: '/blog/dragonchain-awarded-us-interchain-patent',
                body: 'We hold the patent for Interop between any two blockchains.',
              },
              {
                title: 'Blockchain Scalability',
                link: '/blog/dragonchain-receives-patent-for-blockchain-loyalty',
                body: 'We hold the patent to scale blockchains based on the scarcity of time.',
              },
              {
                title: 'Smart Contract Orchestration',
                link: '/blog/dragonchain-awarded-smart-contract-patent',
                body: 'We hold the patent for orchestration of smart contracts for business processes.',
              },
            ]}
          />
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default About;
